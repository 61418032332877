var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('snackbar',{ref:"snackbar",attrs:{"message":_vm.snackbarMessage}}),_c('v-overlay',{staticClass:"ml-overlay",attrs:{"color":"white","value":_vm.getOverlay,"opacity":"0.3","z-index":"1500"}}),_c('div',{staticClass:"d-flex w-100 justify-space-between align-items-center"},[_c('div',{staticClass:"mt-4 text-h6"},[_vm._v("Update settings on organizational level.")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.getAuthUser.user_type === 'admin')?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4 ml-auto",attrs:{"outlined":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openAddAccountForm('add')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Add Account")])])],1),_c('div',{staticClass:"pb-0 mt-4"},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","clearable":"","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',_vm._l((_vm.accounts),function(account,index){return _c('v-list',{key:index,attrs:{"flat":""}},[_c('v-list-item',{staticClass:"pr-2"},[_c('v-list-item-content',[_c('div',{staticClass:"font-600"},[_vm._v(_vm._s(account.name))])]),_c('v-list-item-action',[_c('div',{staticClass:"justify-start d-flex align-center mr-9"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.getAuthUser.user_type === 'admin')?_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","icon":"","small":""},on:{"click":function($event){return _vm.openAccountForm('edit', account)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit Account")])]),(_vm.getAuthUser.user_type === 'admin')?_c('v-divider',{staticClass:"mx-3",attrs:{"color":"default","vertical":""}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.getAuthUser.user_role === 'master')?_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","icon":"","small":""},on:{"click":function($event){return _vm.openAccountForm('add', account)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Add New Organization")])])],1)])],1),_c('v-treeview',{attrs:{"items":account.organizations,"open-on-click":"","expand-icon":"mdi-chevron-down","item-key":"id","transition":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-card',{key:item.id,staticClass:"justify-start shadow-none d-flex flex-column",attrs:{"color":"transparent"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"justify-start d-flex align-center"},[_c('v-list-item-icon',{staticClass:"ml-2 mr-4"},[_c('v-avatar',{attrs:{"size":"30","color":item.color}})],1),_c('div',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v(_vm._s(item.address))])],1)],1),(
                  _vm.getAuthUser.user_role === 'master' ||
                  _vm.getAuthUser.user_type === 'admin'
                )?_c('div',{staticClass:"justify-start d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showModal('edit', item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"outlined":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showModal('add', item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Organization")])]),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var menu = ref.on;
                var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","small":"","outlined":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Users")])])]}}],null,true),model:{value:(_vm.menu[("menu-" + (item.id))]),callback:function ($$v) {_vm.$set(_vm.menu, ("menu-" + (item.id)), $$v)},expression:"menu[`menu-${item.id}`]"}},[_c('v-card',[_c('v-card-subtitle',[_vm._v("Add Users to "+_vm._s(item.name))]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"multiple":"","outlined":"","dense":"","hide-details":"","items":_vm.filterUsers(_vm.getSellers, item),"item-text":"name","item-value":"id","label":"Select Users"},on:{"focus":function($event){_vm.focusedOrg = item.id}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [(index === 0)?_c('v-chip',{staticClass:"m-1 mt-1 mb-1",attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.usersLength - 1)+" more) ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" No Users Found ")])],1)]},proxy:true}],null,true),model:{value:(_vm.users[("org-" + (item.id))]),callback:function ($$v) {_vm.$set(_vm.users, ("org-" + (item.id)), $$v)},expression:"users[`org-${item.id}`]"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu[("menu-" + (item.id))] = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.updateUsers(item.id)}}},[_vm._v("Add")])],1)],1)],1)],1):_vm._e()]),(item.users && item.users.length > 0)?_c('div',{staticClass:"members-section"},[_c('v-subheader',{staticClass:"py-0 my-0 text-uppercase members-title"},[_vm._v("Users")]),_c('v-list-item',{staticClass:"d-block"},_vm._l((item.users),function(user){return _c('v-chip',{key:("loc-users-" + (user.id) + "-" + (item.id)),staticClass:"mb-2 mr-2",attrs:{"color":'default',"close":_vm.getAuthUser.user_role === 'master' ||
                    _vm.getAuthUser.user_type === 'admin',"close-icon":"mdi-close","value":user.id,"label":""},on:{"click:close":function($event){return _vm.deleteOrganizationUser({
                      organization_id: item.id,
                      user_id: user.id,
                    })}}},[_c('v-avatar',{staticClass:"mr-2 text--secondary text-body-2",attrs:{"size":"20","color":"white"}},[_vm._v(" "+_vm._s(user.initials)+" ")]),_c('div',{staticClass:"justify-center d-flex flex-column align-start"},[_c('div',{staticClass:"user-title"},[_vm._v(" "+_vm._s(user.first_name)+" "+_vm._s(user.last_name)+" ")])])],1)}),1)],1):_c('div',{staticClass:"pb-4 pl-3 ml-10 faded--text font-italic"},[_vm._v(" No Users Available ")])])]}}],null,true)})],1)}),1),(!_vm.accounts.length)?_c('div',{staticClass:"justify-center mt-16 d-flex flex-column align-center"},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-cloud-off-outline ")]),_c('div',{staticClass:"mt-2 text-subtitle-2 text--secondary"},[_vm._v("No Results Found")])],1):_vm._e(),_c('organization-form',{ref:"organizationForm",attrs:{"formType":_vm.organizationFormType,"dialogState":_vm.organizationFormState},on:{"successMessage":_vm.organizationSuccessMessage,"close-form":_vm.closeOrganizationForm}}),_c('account-form',{ref:"accountForm",attrs:{"formType":_vm.accountFormType,"dialogState":_vm.accountFormState},on:{"successMessage":_vm.accountSuccessMessage,"close-form":_vm.closeAccountForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }