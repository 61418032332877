<template>
  <div>
    <snackbar ref="snackbar" :message="snackbarMessage"></snackbar>
    <v-overlay
      class="ml-overlay"
      color="white"
      :value="getOverlay"
      opacity="0.3"
      z-index="1500"
    ></v-overlay>
    <div class="d-flex w-100 justify-space-between align-items-center">
      <div class="mt-4 text-h6">Update settings on organizational level.</div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="getAuthUser.user_type === 'admin'"
            @click.stop="openAddAccountForm('add')"
            v-bind="attrs"
            class="mt-4 ml-auto"
            v-on="on"
            outlined
            icon
            small
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Account</span>
      </v-tooltip>
    </div>

    <div class="pb-0 mt-4">
      <v-text-field
        v-model="search"
        outlined
        append-icon="mdi-magnify"
        label="Search"
        clearable
        single-line
        hide-details
        dense
      ></v-text-field>
    </div>

    <!-- <v-divider class="mb-0" color="default"></v-divider> -->

    <!--    <organization-skeleton-->
    <!--      v-if="getAccountsLoadStatus === 1"-->
    <!--    ></organization-skeleton>-->
    <div>
      <v-list flat v-for="(account, index) in accounts" :key="index">
        <v-list-item class="pr-2">
          <v-list-item-content>
            <div class="font-600">{{ account.name }}</div>
          </v-list-item-content>

          <v-list-item-action>
            <div class="justify-start d-flex align-center mr-9">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    outlined
                    v-on="on"
                    icon
                    small
                    v-if="getAuthUser.user_type === 'admin'"
                    @click="openAccountForm('edit', account)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Account</span>
              </v-tooltip>

              <v-divider
                class="mx-3"
                color="default"
                vertical
                v-if="getAuthUser.user_type === 'admin'"
              ></v-divider>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    outlined
                    v-on="on"
                    icon
                    small
                    v-if="getAuthUser.user_role === 'master'"
                    @click="openAccountForm('add', account)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Organization</span>
              </v-tooltip>
            </div>
          </v-list-item-action>
        </v-list-item>

        <!--Tree View (START)-->
        <v-treeview
          :items="account.organizations"
          open-on-click
          expand-icon="mdi-chevron-down"
          item-key="id"
          transition
        >
          <template v-slot:label="{ item, open }">
            <v-card
              class="justify-start shadow-none d-flex flex-column"
              :key="item.id"
              color="transparent"
            >
              <!-- START Organization Info -->
              <div class="d-flex justify-space-between">
                <!-- START Icon and Label -->
                <div class="justify-start d-flex align-center">
                  <v-list-item-icon class="ml-2 mr-4">
                    <v-avatar size="30" :color="item.color"></v-avatar>
                  </v-list-item-icon>
                  <div>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle class="text-caption">{{
                      item.address
                    }}</v-list-item-subtitle>
                  </div>
                </div>
                <!-- END Icon and Label -->

                <!-- START Actions -->
                <div
                  class="justify-start d-flex align-center"
                  v-if="
                    getAuthUser.user_role === 'master' ||
                    getAuthUser.user_type === 'admin'
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click.stop="showModal('edit', item)"
                        v-bind="attrs"
                        outlined
                        v-on="on"
                        icon
                        small
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-1"
                        @click.stop="showModal('add', item)"
                        v-bind="attrs"
                        outlined
                        v-on="on"
                        icon
                        small
                      >
                        <v-icon small>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Add Organization</span>
                  </v-tooltip>
                  <v-menu
                    v-model="menu[`menu-${item.id}`]"
                    :close-on-content-click="false"
                    offset-y
                  >
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            class="ml-1"
                            icon
                            small
                            outlined
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                          >
                            <v-icon small>mdi-account-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Users</span>
                      </v-tooltip>
                    </template>

                    <v-card>
                      <v-card-subtitle
                        >Add Users to {{ item.name }}</v-card-subtitle
                      >
                      <v-card-text>
                        <v-autocomplete
                          v-model="users[`org-${item.id}`]"
                          multiple
                          outlined
                          dense
                          hide-details
                          :items="filterUsers(getSellers, item)"
                          item-text="name"
                          item-value="id"
                          @focus="focusedOrg = item.id"
                          label="Select Users"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              v-if="index === 0"
                              color="primary"
                              small
                              class="m-1 mt-1 mb-1"
                              >{{ item.name }}</v-chip
                            >
                            <span v-if="index === 1" class="grey--text caption">
                              (+{{ usersLength - 1 }} more)
                            </span>
                          </template>

                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title>
                                No Users Found
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="menu[`menu-${item.id}`] = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="primary"
                          text
                          @click="updateUsers(item.id)"
                          >Add</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <!-- END Organization Info -->

              <!-- START Members -->
              <div
                class="members-section"
                v-if="item.users && item.users.length > 0"
              >
                <v-subheader class="py-0 my-0 text-uppercase members-title"
                  >Users</v-subheader
                >

                <v-list-item class="d-block">
                  <v-chip
                    :color="'default'"
                    :close="
                      getAuthUser.user_role === 'master' ||
                      getAuthUser.user_type === 'admin'
                    "
                    close-icon="mdi-close"
                    :value="user.id"
                    label
                    v-for="user in item.users"
                    :key="`loc-users-${user.id}-${item.id}`"
                    @click:close="
                      deleteOrganizationUser({
                        organization_id: item.id,
                        user_id: user.id,
                      })
                    "
                    class="mb-2 mr-2"
                  >
                    <v-avatar
                      size="20"
                      color="white"
                      class="mr-2 text--secondary text-body-2"
                    >
                      {{ user.initials }}
                    </v-avatar>
                    <div class="justify-center d-flex flex-column align-start">
                      <div class="user-title">
                        {{ user.first_name }} {{ user.last_name }}
                      </div>
                    </div>
                  </v-chip>
                </v-list-item>
              </div>
              <div class="pb-4 pl-3 ml-10 faded--text font-italic" v-else>
                No Users Available
              </div>
              <!-- END Members -->
            </v-card>
          </template>
        </v-treeview>
        <!--Tree View (END)-->
      </v-list>

      <!--      <infinite-loading @infinite="infiniteAccounts"></infinite-loading>-->
    </div>

    <div
      v-if="!accounts.length"
      class="justify-center mt-16 d-flex flex-column align-center"
    >
      <v-icon large> mdi-cloud-off-outline </v-icon>
      <div class="mt-2 text-subtitle-2 text--secondary">No Results Found</div>
    </div>

    <organization-form
      ref="organizationForm"
      @successMessage="organizationSuccessMessage"
      @close-form="closeOrganizationForm"
      :formType="organizationFormType"
      :dialogState="organizationFormState"
    ></organization-form>

    <account-form
      ref="accountForm"
      @successMessage="accountSuccessMessage"
      @close-form="closeAccountForm"
      :formType="accountFormType"
      :dialogState="accountFormState"
    ></account-form>

    <!-- <div class="mt-16 text-right">
      <v-pagination
        v-model="page"
        :length="10"
        :total-visible="10"
      ></v-pagination>
    </div> -->
  </div>
</template>

<script>
const OrganizationSkeleton = () =>
  import(
    /* webpackChunkName: "organization-skeleton" */ "@/components/skeletons/OrganizationSkeleton"
  );
const OrganizationForm = () =>
  import(/* webpackChunkName: "organization-form" */ "./OrganizationForm");
const AccountForm = () =>
  import(/* webpackChunkName: "account-form" */ "./AccountForm");
import Snackbar from "@/components/Snackbar";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "OrganizationManagement",
  components: { OrganizationSkeleton, OrganizationForm, AccountForm, Snackbar },
  data: () => ({
    page: 1,
    organizationFormType: "add",
    accountFormType: "add",
    organizationFormState: false,
    accountFormState: false,
    snackbarMessage: "",
    menu: {},
    skipUsers: [],
    activeOrganizationUser: {},
    users: {},
    focusedOrg: null,
    search: "",
  }),
  computed: {
    accounts() {
      if (!this.search) {
        return this.getAccounts;
      }

      const keyword = this.search.toLowerCase();
      return this.getAccounts.filter((account) => {
        let orgNames = "";
        account.organizations.some((org) => {
          orgNames += org.name.toLowerCase() + "|";
        });
        return (
          account.name.toLowerCase().includes(keyword) ||
          orgNames.includes(keyword)
        );
      });
    },
    usersLength() {
      return this.users[`org-${this.focusedOrg}`].length;
    },
    ...mapGetters([
      "getAccounts",
      "getAccount",
      "getOrganizations",
      "getAccountsLoadStatus",
      "getAuthUser",
      "getSellers",
      "getOverlay",
    ]),
  },
  methods: {
    async infiniteAccounts($state) {
      let len = await this.loadAccounts();
      if (len && !!$state) {
        $state.loaded();
      } else if (!!$state) $state.complete();
    },
    openAddAccountForm(type) {
      this.accountFormType = type;
      this.accountFormState = true;
    },
    openAccountForm(type, account = {}) {
      this.setAccountByAccountId(account.id);
      if (type === "add") {
        this.showModal(type, {});
      } else {
        this.accountFormType = type;
        this.accountFormState = true;
      }
    },
    filterUsers(sellers = [], item = {}) {
      let users = _.reject(sellers, (user) =>
        _.some(item.users, { id: user.id })
      );
      users = _.reject(users, (user) => {
        return user.account_id !== item.account_id;
      });
      users = users.map((user) => {
        user.name = user.first_name + " " + user.last_name;
        return user;
      });
      return users;
    },
    async showModal(type = "add", organization = {}) {
      this.organizationFormType = type;
      if (organization && organization.account_id)
        await this.setAccountByAccountId(organization.account_id);
      await this.setOrganization(organization);
      this.organizationFormState = true;
    },
    organizationSuccessMessage(type = "add") {
      type = type === "add" ? "added" : "updated";
      this.snackbarMessage = `Organization ${type} successfully`;
      this.$refs.snackbar.type = "success";
      this.$refs.snackbar.show = true;
    },
    accountSuccessMessage(type = "add") {
      type = type === "add" ? "added" : "updated";
      this.snackbarMessage = `Account ${type} successfully`;
      this.$refs.snackbar.type = "success";
      this.$refs.snackbar.show = true;
    },
    closeOrganizationForm() {
      this.organizationFormState = false;
    },
    closeAccountForm() {
      this.accountFormState = false;
    },
    filteredUsers(organization) {
      this.activeOrganizationUser = organization;
      if (organization.users != null) {
        let i = 0;
        this.skipUsers = [];
        for (i = 0; i < organization.users.length; i++) {
          this.skipUsers.push(organization.users[i].id);
        }
      }
    },
    async updateUsers(organization) {
      this.menu[`menu-${organization}`] = false;
      await this.updateOrganizationUser({
        user_id: this.users[`org-${organization}`],
        organization_id: organization,
      });
      this.loadAccounts();
      this.users = [];
    },
    async deleteOrganizationUser(payload) {
      await this.updateOrganizationUser(payload);
      this.loadAccounts();
    },
    ...mapMutations(["setAccount", "setOrganization", "setAccountByAccountId"]),
    ...mapActions(["loadAccounts", "updateOrganizationUser"]),
  },
};
</script>
<style scoped>
::v-deep .v-treeview .v-treeview-node {
  border-bottom: 1px solid lightgray !important;
  border-radius: 0px !important;
}
::v-deep .v-treeview-node__children .v-treeview-node {
  border-bottom: 0px !important;
}
.actions-divider {
  height: 32px;
  margin-top: 16px;
}
</style>